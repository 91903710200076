import React from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import { Spinner } from '../common/index'
import { connect } from 'react-redux'

const Auth = React.lazy(() => import('../pages/auth/auth'))
const ProtectedWebPage = React.lazy(() => import('./protected-router'))

function WebPage(props) {
	React.useEffect(() => {
		// window.OverlayScrollbars(document.querySelectorAll('body'), { "scrollbars": { "autoHide": 'scroll' } })
		const Mumbai = {
			current: {
				district: 'Mumbai',
				latitude: 19.0344,
				location_id: 170,
				longitude: 72.8537,
				state: 'MAHARASHTRA',
			},
			base: {
				district: 'Mumbai',
				latitude: 19.0344,
				location_id: 170,
				longitude: 72.8537,
				state: 'MAHARASHTRA',
			},
		}
		if(!props.isLoggedIn){
			console.log("adding location")
			props.updateLocation(Mumbai)}
	}, [])

	return (
		<React.Suspense fallback={<Spinner />}>
			<Router>
				<Switch>
					{/* <Route path='/app/' component={ProtectedWebPage} /> */}
					<Route path='/app/' component={ProtectedWebPage} />
					<Route path='/' component={Auth} />
					{/* <Route path='/' component={Auth} /> */}
				</Switch>
			</Router>
		</React.Suspense>
	)
}

const mapStateToProps = (state) => {
	return {
		token: state.general.token,
		profile: state.general.profile,
		brandname: state.general.brandname,
		location: state.general.location,
		isLoggedIn: state.general.isLoggedIn
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		updateLocation: (Mumbai)=>{ dispatch({type: 'UPDATE_LOCATION', location: Mumbai})}
	}
}
export default connect(mapStateToProps, mapDispatchToProps)(WebPage)

