import React from 'react'
import classname from 'classnames'

function PictureCarousel(props) {
    return (
        <div>
            {props.previewUrl!== undefined && props.previewUrl.length > 0 ? (
			<>				
				<div
					id={'carouselControls' + props.id}
					className='carousel slide carousel-dark'
					data-bs-ride='carousel'
				>
					<div className='carousel-inner'>
						{props.previewUrl.map((img, i) => (
							<div
								className={classname('carousel-item text-center', {
									active: i === 0,
								})}
								key={'img_' + i}
							>
								
								{img.type === 0  ? (
									<img
										style={props.style!==undefined ? props.style: { borderRadius: "2rem", maxHeight: "600px", maxWidth: "800px"}}
										className={props.className? props.className: 'w-100 rounded-5'}
										src={img.url}
										alt={'img_' + i}
									/>
								) : (
									<video
									style={{ maxHeight: "400px", maxWidth: "300px"}}
									className='w-100'
										controls
									>
										<source src={img.url} />
										Your browser does not support the video tag.
									</video>
								)}
							</div>
						))}
					</div>
				
					{props.previewUrl!== undefined && props.previewUrl.length > 1 ? (
						<>
						
							<button
								className='carousel-control-prev'
								type='button'
								data-bs-target={'#carouselControls' + props.id}
								data-bs-slide='prev'
							>
								<span className='carousel-control-prev-icon' aria-hidden='true'></span>
								<span className='visually-hidden'>Previous</span>
							</button>

							<button
								className='carousel-control-next'
								type='button'
								data-bs-target={'#carouselControls' + props.id}
								data-bs-slide='next'
							>
								<span className='carousel-control-next-icon' aria-hidden='true'></span>
								<span className='visually-hidden'>Next</span>
							</button>
						</>
					) : (
						<></>
					)}
				</div>
				</>
			) : (
				<></>
			)}
        </div>
    )
}

export default PictureCarousel
