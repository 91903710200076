const api_links = {
	//AUTH
	oauth: { callurl: '/auth/authorization', callmethod: 'POST' },
	oauth_response: { callurl: '/auth/fetchtoken', callmethod: 'POST' },
	logout:{callurl:'/auth/logout',callmethod:'DELETE'},

	//GROUP
	get_group_profile: { callurl: '/group/profile', callmethod: 'GET' },
	get_group_post: { callurl: '/group/post', callmethod: 'GET' },
	get_users_list: { callurl: '/group/member', callmethod: 'GET' },
	grp_checkname: { callurl: '/group/checkname', callmethod: 'GET' },
	grp_create: { callurl: '/group/', callmethod: 'POST' },
	get_group: { callurl: '/group/', callmethod: 'GET' },
	edit_group: { callurl: '/group/', callmethod: 'PUT' },
	join_group: { callurl: '/group/join', callmethod: 'POST' },
	approve_group: { callurl: '/group/approve', callmethod: 'POST' },
	change_role: { callurl: '/group/role', callmethod: 'PUT' },
	get_group_nearby: { callurl: '/group/nearby', callmethod: 'GET' },
	get_non_auth_nearbygroups: {
		callurl: '/group/nearby/plain',
		callmethod: 'GET',
	},
	get_group_list:{callurl:'/group/joined',callmethod:'GET'},
	group_search:{callurl:'/group/search',callmethod:'GET'},
	group_delete:{callurl:'/group/',callmethod:'DELETE'},
	get_group_member:{callurl:'/group/addmember',callmethod:'GET'},
	join_group_member:{callurl:'/group/addmember',callmethod:'POST'},

	//POST
	post_create: { callurl: '/post/', callmethod: 'post' },
	post_get: { callurl: '/post/', callmethod: 'get' },
	post_update: { callurl: '/post/', callmethod: 'put' },
	post_delete: { callurl: '/post/', callmethod: 'delete' },
	remove_asset: { callurl: '/post/getby/', callmethod: 'delete' },
	non_auth_post_get: { callurl: '/post/plain/', callmethod: 'get' },
	post_like:{callurl:'/post/like', callmethod:'put'},
	//POLL
	poll_create: { callurl: '/poll/', callmethod: 'post' },
	poll_vote: { callurl: '/reaction/vote', callmethod: 'put' },
	poll_update:{callurl: '/poll/', callmethod: 'put' },
	poll_vote_option: { callurl: '/poll/vote', callmethod: 'post' },

	//REVIEW
	review_create: { callurl: '/review/', callmethod: 'post' },
	review_update: { callurl: '/review/', callmethod: 'put' },

	//EVENT
	event_create: { callurl: '/event/', callmethod: 'post' },
	event_update: { callurl: '/event/', callmethod: 'put' },

	//REACTION
	put_reaction: { callurl: '/reaction/', callmethod: 'put' },

	//COMMENT
	get_comment: { callurl: '/comment/', callmethod: 'get' },
	post_comment: { callurl: '/comment/', callmethod: 'post' },
	put_comment: { callurl: '/comment/', callmethod: 'put' },
	delete_comment: { callurl: '/comment/', callmethod: 'delete' },

	//REPLY
	get_reply: { callurl: '/comment/reply', callmethod: 'get' },
	post_reply: { callurl: '/comment/reply', callmethod: 'post' },
	put_reply: { callurl: '/comment/reply', callmethod: 'put' },
	delete_reply: { callurl: '/comment/reply', callmethod: 'delete' },

	//USER
	get_user_post: { callurl: '/user/post', callmethod: 'get' },
	get_liked_post: { callurl: '/user/liked', callmethod: 'get' },
	get_user_groups: { callurl: '/group/user', callmethod: 'get' },
	edit_profile: { callurl: '/user/', callmethod: 'PUT' },
	get_user_profile: { callurl: '/user/', callmethod: 'GET' },
	post_user_follow: { callurl: '/user/connect', callmethod: 'POST' },
	get_followers: { callurl: '/user/followers', callmethod: 'GET' },
	get_user_nearby: { callurl: '/user/nearby', callmethod: 'GET' },
	get_non_auth_nearbyusers: { callurl: '/user/nearby/plain', callmethod: 'GET' },
	get_user_group:{callurl:'/user/group',callmethod:'GET'},
	get_connection:{callurl:'/user/connection',callmethod:'GET'},
	post_user_connect:{callurl:'/user/connect/accept',callmethod:'POST'},
	post_user_decline:{callurl:'/user/connect/decline',callmethod:'POST'},

	//LOCATION
	get_location: { callurl: '/location/', callmethod: 'GET' },
	get_current_location: { callurl: '/location/near', callmethod: 'GET' },

	//GlOBAL_SEARCH
	get_global_search: { callurl: '/search/', callmethod: 'GET' },

	// QUIZ
	get_quiz: { callurl: '/quiz/question/', callmethod: 'GET' },
	get_points: { callurl: '/quiz/points/', callmethod: 'GET' },
	put_ans: { callurl: '/quiz/question/', callmethod: 'PUT' },
	delete_answered_question: { callurl: '/quiz/question/', callmethod: 'delete' },
	put_result: { callurl: '/quiz/result/', callmethod: 'PUT' },

	// Notification
	get_notification: { callurl: '/notification/', callmethod: 'GET' },
	delete_notification: { callurl: '/notification/', callmethod: 'DELETE' },
	read_notification: { callurl: '/notification/', callmethod: 'PUT' },

	// HouseHelper methods
	house_help: { callurl: '/service/', callmethod: 'POST' },

	//BOT
	get_employee: { callurl: '/bot/employee/', callmethod: 'GET' },

	//Pet Comerce
	post_pet_details: {callurl: '/pet/', callmethod: 'POST'},
	get_pet_details: {callurl: '/pet/', callmethod: 'GET'},
	get_single_pet: {callurl: '/pet/getby/', callmethod: 'GET'},
	add_to_cart: {callurl: '/pet/cart/', callmethod: 'POST'},
	get_cart: {callurl: '/pet/cart/', callmethod: 'GET'},
	get_by_category: {callurl: '/pet/filter/', callmethod: 'GET'},
	get_categories: {callurl: '/pet/categories/', callmethod: 'GET'},
	remove_from_cart: {callurl: '/pet/cart/', callmethod: 'DELETE'},

}

export { api_links }
