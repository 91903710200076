const menu = [
	{ name: 'Home', icon: 'house', to: '/app/', mobile: true },
	{ name: 'Groups', icon: 'people', to: '/app/group', mobile: true },
	{
		name: 'Househelp',
		icon: 'basket',
		to: '/app/househelp',
		mobile: true,
	},
	{
		name: 'Petcommerce',
		icon: 'house',
		to: '/app/pet_commerce/custom',
		mobile: true,
	},
]
const bottomMenu = [
	{ name: 'Home', icon: 'house', to: '/app/', mobile: true },
	// { name: 'Groups', icon: 'people', to: '/app/group', mobile: true },
	// {
	// 	name: 'Profile',
	// 	icon: 'person',
	// 	to: '/app/profile/self',
	// 	mobile: true,
	// },
]

export { menu, bottomMenu}