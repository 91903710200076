import React from "react";

export const Viewalllrightarrow=()=>
{
    return(
        <>
            <svg className='mx-1' width="6" height="10" viewBox="0 0 6 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1 9L5 5L1 1" stroke="#2B00B2" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>


        </>
    )
}
