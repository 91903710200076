export const generalstate = {
	profile: {},
	token: {},
	location: {},
	group: [],
	brandname: 'IndiaConnects',
	sId: [],
	cart: [],
	isLoggedIn: false,
}
